<template>
  <div
    class="home"
    :style="pageStyle"
    style="height:100vh;overflow-y:scroll;"
    @scroll="handleScroll"
    ref="scrollBox"
  >
    <CommonHeader title="拍品详情" />
    <van-pull-refresh v-model="loadingr" @refresh="onRefresh" success-text="刷新成功">
    <div
      class="album-header"
      v-show="auctionHeaderShow"
    >
      <div class="album-header-t flex align-items-c">
        <div
          class="back flex align-items-c"
          @click="backAction"
        >
          <img
            src="https://artimg2.artart.cn/image/20220218/98e4c14a8b9d458a401c20a2ed1666fa.png"
            alt=""
          >
        </div>
        <div class="n fw500 line1 flex-one">{{ auctionData.lot ? 'LOT' + auctionData.lot + ' ' : '' }}{{ auctionData && auctionData.product ? auctionData.product.store_name : '' }}</div>
      </div>
    </div>
    <template v-if="pageContentData">
      <template
        v-for="(item, index) in pageContentData"
        :key="`pageContentData${index}`"
      >
        <template v-if="item.content.type === 'banner'">
          <AuctionSwiper
            :auctionData="auctionData"
            :facade="item.facade"
            :pageInfo="pageStyleData"
          />
        </template>
        <template v-else-if="item.content.type === 'status'">
          <Status
            :auctionData="auctionData"
            :facade="item.facade"
            :pageStyleData="pageStyleData"
            @countDownFinish="countDownFinish"
          />
        </template>
        <template v-else-if="item.content.type === 'name'">
          <Name
            :auctionData="auctionData"
            :facade="item.facade"
            :pageInfo="pageStyleData"
          />
        </template>
        <template v-else-if="item.content.type === 'desc'">
          <Desc
            :auctionData="auctionData"
            :facade="item.facade"
            :pageInfo="pageStyleData"
          />
        </template>
        <template v-else-if="item.content.type === 'price'">
          <Price
            :auctionData="auctionData"
            :facade="item.facade"
            :pageStyleData="pageStyleData"
            :merchant="auctionData.merchant"
          />
        </template>
        <template v-else-if="item.content.type === 'detail'">
          <Detail
            :auctionData="auctionData"
            :facade="item.facade"
            :pageInfo="pageStyleData"
            @showBidRangeAction="showBidRangeAction"
            :merchant="auctionData.merchant"
          />
        </template>
        <template v-else-if="item.content.type === 'message'">
          <Message
            :auctionData="auctionData"
            :facade="item.facade"
            :pageInfo="pageStyleData"
            @showBidRangeAction="showBidRangeAction"
          />
        </template>
        <template v-else-if="item.content.type === 'bid_record'">
          <BidRecord
            :auctionData="auctionData"
            :facade="item.facade"
            :pageInfo="pageStyleData"
            :list="recordList"
            :total="recordTotal"
            @loadMore="loadMoreAction"
            :merchant="auctionData.merchant"
          />
        </template>
        <template v-else-if="item.content.type === 'list' && recommendList.length && listFacade">
          <div :style="{ padding: `0 ${(pageStyleData.page_margin / 75)}rem`, background: item.facade.background, marginTop: (item.facade.marginTop / 75) + 'rem' }">
            <div :style="{ lineHeight: (pageStyleData.tLineHeight / 75) + 'rem', fontSize: (pageStyleData.tFontSize / 75) + 'rem', color: pageStyleData.tColor, fontWeight: pageStyleData.tFontWeight }">相关拍品</div>
            <div
              class="list"
              :style="{ margin: `0 -${listFacade.left_margin / 75}rem` }"
            >
              <Auction
                :content="{ renderData: recommendList, style: 5 }"
                :facade="listFacade"
                jump="2"
              ></Auction>
            </div>
          </div>
        </template>
      </template>
    </template>
    <AuctionBottomBtn
      :auctionData="auctionData"
      :isPayBond="isPayBond"
      @bid="showBidAction"
      @userNumberPlate="getNumberPlateAction"
      @showPayBondMoney="showPayBondMoneyAction"
      @proxyBidAction="proxyBidAction"
    />
    <BidPrice
      :auctionDetail="auctionData"
      :showBid="showBid"
      :watchIndex="watchIndex"
      :compel_anonymous="compel_anonymous"
      @ok="bidOk"
      @close="closeAction"
      @proxyBidAction="proxyBidAction"
    ></BidPrice>
    <BidBondMoney
      :showPay="showPayBondMoney"
      :auctionData="auctionData.bond_price ? auctionData : ''"
      :albumData="auctionData.album && auctionData.album.bond_price ? auctionData.album : ''"
      :account="account"
      @payOk="payOk"
      @payClear="payClear"
    ></BidBondMoney>
    <AuctionProxy
      ref="proxy"
      :compel_anonymous="compel_anonymous"
    />
    </van-pull-refresh>
    <van-dialog
      v-model:show="showBidRange"
      title="竞价阶梯"
      :show-confirm-button="false"
      close-on-click-overlay
    >
      <div
        class="range-view"
        v-if="auctionData.bid_type == 2 && auctionData.bidRange"
      >
        <div
          class="jjjt item-flex-center"
          v-for="(item, index) in auctionData.bidRange.content"
          :key="`listRange${index}`"
        >
          <div class="l">{{ item.min }} - {{ item.max == '∞' ? '无限' : item.max }}</div>
          <div
            class="r"
            v-if="item.type == 1"
          >加价{{formatPriceNumber(item.price, 2)}}</div>
          <div
            class="r"
            v-else-if="item.type == 2"
          >2位2580规则</div>
          <div
            class="r"
            v-else-if="item.type == 3"
          >3位2580规则</div>
        </div>
      </div>
      <div class="common-dialog-btns flex">
        <div
          class="common-dialog-btn common-dialog-r-btn"
          :style="rightBtnStyle"
          @click="hideBidRangeAction"
        >确认</div>
      </div>
    </van-dialog>

    <van-dialog
      v-model:show="showModal"
      title="参加即刻拍请注意"
      :show-confirm-button="false"
      close-on-click-overlay
    >
      <div class="caozuotishi flex align-items-c justify-c">
        <div class="czts-content">
          <div class="czts-c-view">
            <div class="czts-c ft28">1、如出价后{{ auctionData.album.interval_time / 60 }}分钟内无他人加价，则拍卖成交</div>
            <div class="czts-c ft28 mt10">2、请于成交之时起{{ order_timeout }}小时内付清全款，否则将取消订单<template v-if="auctionData.album.bond_type == 1">并扣除保证金</template><template v-else-if="auctionData.album.bond_type == 2">并扣除成交价{{100 / auctionData.album.bond_bid_ratio}}%的保证金</template>。</div>
          </div>
          <div
            class="czts-btn textAlignC fw500"
            @click="hideModalAction"
          >我知道了</div>
        </div>
      </div>
    </van-dialog>
    <FollowQrcodeUrl
      :is-show="is_follow_official_account === false && qrcode_url"
    ></FollowQrcodeUrl>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ref, onBeforeMount, computed, reactive, watch } from "vue";
import { useEventListener } from "@vant/use";
import { getConfigClassKeys } from "@/api/system";
import {
  auctionList,
  auctionDetail,
  bidRecordList,
  checkBondPay,
} from "@/api/auction";
import { loginSocketApi, joinRoomApi } from "@/api/live";
import { usePageVisibility } from '@vant/use';
import { formatPriceNumber } from "@/utils/util";
import { Toast } from "vant";
import { getDiyPage } from "@/api/system";
import AuctionSwiper from "./modules/AuctionSwiper.vue";
import Desc from "./modules/AuctionDesc.vue";
import Price from "./modules/AuctionPrice.vue";
import Status from "./modules/AuctionStatus.vue";
import Name from "./modules/AuctionName.vue";
import Detail from "./modules/AuctionDetail.vue";
import BidRecord from "./modules/AuctionBidRecord.vue";
import Message from "./modules/AuctionMessage.vue";
import AuctionBottomBtn from "./modules/AuctionBottomBtn.vue";
import BidPrice from "./modules/AuctionBidPrice.vue";
import BidBondMoney from "./modules/BidBondMoney.vue";
import Auction from "@/components/DiyComponent/Auction";
import AuctionProxy from "@/components/AuctionProxy";
import { shareConfig } from "@/utils/share";
import FollowQrcodeUrl from "@/components/FollowQrcodeUrl";

import webSocket from "@/utils/webSocket";

export default {
  components: {
    Auction,
    AuctionSwiper,
    Desc,
    Price,
    Status,
    Name,
    Detail,
    BidRecord,
    Message,
    BidPrice,
    BidBondMoney,
    AuctionBottomBtn,
    AuctionProxy,
    FollowQrcodeUrl
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const id = ref(""); // 拍品ID
    const auctionData = ref(""); // 拍品详情信息
    const pageStyleData = ref({}); // 详情页面配置
    const pageContentData = ref(""); // 详情页面组件配置

    const showBid = ref(false); // 出价弹框控制器
    const recordList = ref([]); // 出价记录
    const recordTotal = ref(0); // 出价次数
    const pageVisibility = usePageVisibility();

    const watchIndex = ref(0);

    const sdata = reactive({
      socketServe: webSocket.Instance,
    });

    const isPayBond = ref(false); // 是否缴纳保证金
    const showPayBondMoney = ref(false); // 打开保证金缴纳弹窗

    const showBidRange = ref(false);

    const proxy = ref(null); // 代理出价子组件
    const compel_anonymous = ref(0);
    const showModal = ref(false);

    const loadDetail = () => {
      // 拍品详情
      auctionDetail(id.value)
        .then((res) => {
          Toast.clear();
          if (
            (res.data.album && res.data.album.bond_type == 1) ||
            !res.data.album
          ) {
            isPayBond.value = res.data.isPayBond;
          }
          auctionData.value = res.data;
          watchIndex.value += 1;

          if (auctionData.value.auction_type == 4) {
            showModal.value = true;
          }

          shareConfig(
            res.data.product.store_name,
            res.data.product.store_info,
            res.data.product.image,
            "",
            "",
            location.href
          );

          loadRecommend(res.data.album.album_id);
        })
        .catch((message) => {
          Toast(message);
        });
    };

    const loadAccount = () => {
      store.dispatch("user/getAccountInfo");
    };

    const recommendList = ref([]);

    const loadRecommend = (id) => {
      auctionList({
        page: 1,
        limit: 10,
        album_id: id,
      }).then((res) => {
        recommendList.value = res.data.list;
      });
    };

    const recordPage = ref(1);
    const loadRecord = (refresh) => {
      const data = {
        auction_id: id.value,
        page: recordPage.value,
        limit: 5,
      };
      if (refresh) {
        data.page = 1;
        data.limit = recordList.value.length > 5 ? recordList.value.length : 5;
      }
      // 出价记录
      bidRecordList(data).then((res) => {
        if (data.page == 1) {
          recordTotal.value = res.data.count;
          recordList.value = res.data.list;
        } else {
          recordList.value = recordList.value.concat(res.data.list);
        }
      });
    };
    const listFacade = ref(null);
    const order_timeout = ref(72);

    const token = computed(() => {
      return store.getters.token;
    })

    watch(pageVisibility, (value) => {
      if (value == 'visible' && store.getters.token && !is_follow_official_account.value) {
        store.dispatch("user/getIsFollowOfficialAccountInfo");
      }
    });
    // 挂载之前方法 请求一些基本信息
    onBeforeMount(() => {
      store.dispatch("user/getInfo");
      if (store.getters.token && !is_follow_official_account.value) {
        store.dispatch("user/getIsFollowOfficialAccountInfo");
      }
      id.value = router.currentRoute.value.query.id;

      if (!id.value) {
        Toast("该拍品不存在");
        router.back();
        return false;
      }

      const auctionD = localStorage.getItem("auctionDetail");
      if (auctionD) {
        const aD = JSON.parse(auctionD);
        if (aD.product_id == id.value) {
          auctionData.value = aD;
        }
      }

      const pageStyle = localStorage.getItem("auctionPageStyle");
      if (pageStyle) {
        const pageStyleN = JSON.parse(pageStyle);
        if (pageStyleN.pageStyleData && pageStyleN.pageContentData) {
          pageStyleData.value = pageStyleN.pageStyleData;
          pageContentData.value = pageStyleN.pageContentData;
        }
      }
      loadDetail();

      getConfigClassKeys("auction_rule_set").then((res) => {
        compel_anonymous.value = res.data.compel_anonymous;
        order_timeout.value = res.data.order_timeout || 72;
      });

      // 拍品列表组件
      getDiyPage({
        type: "auction_component",
      }).then((res) => {
        const configData = res.data.config_data;
        let configFacade = {};
        for (let key in configData) {
          if (key === "type_setting") {
            Object.assign(configFacade, configData["type_setting"][5]);
          } else if (key === "page_margin") {
            configFacade[key] = 0;
          } else {
            configFacade[key] = configData[key];
          }
        }
        console.log(configFacade);
        listFacade.value = configFacade;
      });

      // 拍品配置
      getDiyPage({
        type: "auction_detail_page",
      })
        .then((res) => {
          pageStyleData.value = res.data.config_data;
          pageContentData.value = res.data.content;

          localStorage.setItem(
            "auctionPageStyle",
            JSON.stringify({
              pageStyleData: res.data.config_data,
              pageContentData: res.data.content,
            })
          );
        })
        .catch((message) => Toast(message));

      loadRecord(1);
      if (!webSocket.Instance.ws) {
        webSocket.Instance.connect();
        sdata.socketServe = webSocket.Instance;
        sdata.socketServe.registerCallBack("getMessage", (data) => {
          sockedMes(data);
        });
        sdata.socketServe.registerCallBack("openSocket", () => {});
      } else {
        sdata.socketServe.registerCallBack("getMessage", (data) => {
          sockedMes(data);
        });
        joinRoom();
      }
      loadAccount();
    });

    const loadpmLock1 = ref(null);
    const cjjlLoadding = ref(null);
    const loadpmLock = ref(null);
    const client_id = ref("");

    const loginSocket = () => {
      loginSocketApi({
        client_id: client_id.value,
      })
        .then(() => {
          joinRoom();
        })
        .catch((message) => Toast(message));
    };

    const joinRoom = () => {
      joinRoomApi({
        client_id: client_id.value || webSocket.Instance.client_id,
        room_type: "auction",
        room_id: id.value,
      })
        .then()
        .catch((message) => Toast(message));
    };

    const sockedMes = (d) => {
      if (d.type == "init") {
        client_id.value = d.client_id;
        webSocket.Instance.client_id = d.client_id;
        loginSocket();
      } else if (
        (d.type == "auction_bid" || d.type == "auction_bid_proxy") &&
        d.data.id == id.value
      ) {
        if (d.data.auction_status != 1) {
          Toast.loading({
            duration: 0,
            forbidClick: true,
          });
          if (loadpmLock1.value) return false;
          loadpmLock1.value = setTimeout(() => {
            loadDetail();
            loadRecord(1);
            loadpmLock1.value = null;
          }, 1500);
        } else {
          let record = {};
          if (d.type === "auction_bid") {
            record = d.data.bid_record;
          } else {
            record = d.data.bid_record_list[0];
          }
          auctionData.value.now_price = d.data.now_price;
          // this.data.now_price = d.auction_info.now_price;
          if (d.data.goTime) {
            auctionData.value.goTime = d.data.goTime;
          }

          if (d.data.end_time) {
            auctionData.value.end_time = d.data.end_time;
          }
          if (d.data.auction_status) {
            auctionData.value.auction_status = d.data.auction_status;
          }
          if (d.data.goTime) {
            auctionData.value.goTime = d.data.goTime;
          }
          auctionData.value.now_user = record.uid;
          if (d.data.auction_type == 3) {
            auctionData.value.now_price = record.bid_price;
          } else {
            auctionData.value.now_price = d.data.now_price;
          }
          loadNewRecord();
        }
      } else if (d.type === "auction_bid_confirm") {
        // 拍品出价
        if (id.value == d.data.id) {
          auctionData.value.now_price = d.data.now_price;
          auctionData.value.now_user = d.data.bid_record.uid;
          auctionData.value.now_name = d.data.bid_record.user.nickname;
          loadNewRecord();
        }
      } else if (d.type === "auction_bid_del") {
        // 删除最高价
        if (id.value == d.data.id) {
          auctionData.value.now_price = d.data.now_price;
          loadNewRecord();
        }
      } else if (d.type === "auction_remake") {
        // 重拍
        if (id.value == d.data.auction_info.product_id) {
          auctionData.value = d.data.auction_info;
          loadNewRecord();
        }
      } else if (d.type === "auction_edit_bidRange") {
        // 切换竞价阶梯
        if (id.value == d.data.auction_id) {
          auctionData.value.bid_range_id = d.data.bidRange_info.id;
          auctionData.value.bidRange = d.data.bidRange_info;
          watchIndex.value += 1;
        }
      }
    };

    const loadNewRecord = () => {
      watchIndex.value += 1;

      cjjlLoadding.value = true;
      if (loadpmLock.value) clearTimeout(loadpmLock.value);
      loadpmLock.value = setTimeout(() => {
        loadpmLock.value = null;
        loadRecord(1);
      }, 1000);
    };

    const sendData = (data) => {
      sdata.socketServe.send(data);
    };

    // 余额
    const account = computed(() => {
      return store.getters.account;
    });

    // 标题栏 样式
    const headerStyle = computed(() => {
      return {
        paddingTop: 0,
        height: "1.06666rem",
        backgroundColor: pageStyleData.value.header_background,
        color: pageStyleData.value.header_color,
        fontSize: pageStyleData.value.header_text_size / 75 + "rem",
      };
    });
    // 页面配置
    const pageStyle = computed(() => {
      const data = {
        backgroundColor: pageStyleData.value.background,
        backgroundImage: `url(${pageStyleData.value.bg_image})`,
        backgroundRepeat:
          pageStyleData.value.bg_image_mode === 3 ? "repeat-y" : "no-repeat",
        backgroundPosition:
          pageStyleData.value.bg_image_mode === 3 ||
          pageStyleData.value.bg_image_mode === 4
            ? "center top"
            : "center",
      };
      if (pageStyleData.value.bg_image_mode === 1) {
        data.backgroundSize = `contain;`;
      } else if (pageStyleData.value.bg_image_mode === 2) {
        data.backgroundSize = `cover;`;
      } else if (pageStyleData.value.bg_image_mode === 3) {
        data.backgroundSize = `10rem;`;
      } else if (pageStyleData.value.bg_image_mode === 4) {
        data.backgroundSize = `10rem;`;
      }
      return data;
    });

    const showBidAction = () => {
      showBid.value = true;
    };

    const closeAction = () => {
      showBid.value = false;
    };

    const getNumberPlateAction = (number) => {
      // 领取号牌
      auctionData.value.numberPlate = number;
    };

    const checkAlbumBondPay = () => {
      // 检查是否缴纳保证金
      checkBondPay(id.value).then((res) => {
        isPayBond.value = res.data.is_pay;
      });
    };

    const backAction = () => {
      router.back();
    };

    const showPayBondMoneyAction = () => {
      // 打开缴纳保证金弹层
      showPayBondMoney.value = true;
    };

    const payOk = () => {
      // 保证金支付成功
      showPayBondMoney.value = false;
      checkAlbumBondPay();
    };
    const payClear = () => {
      // 取消支付保证金
      showPayBondMoney.value = false;
    };

    const bidOk = () => {
      // 出价成功
      // loadRecord();
      loadAccount();
      showBid.value = false;
      Toast("出价成功");
    };

    // 右按钮样式
    const rightBtnStyle = computed(() => {
      return store.getters.right_button_style
        ? store.getters.right_button_style
        : {};
    });
    // 左按钮样式
    const leftBtnStyle = computed(() => {
      return store.getters.left_button_style
        ? store.getters.left_button_style
        : {};
    });

    const hideBidRangeAction = () => {
      // 隐藏竞价阶梯
      showBidRange.value = false;
    };
    const showBidRangeAction = () => {
      // 查看竞价阶梯
      showBidRange.value = true;
    };

    const proxyBidAction = () => {
      // 设置代理出价
      if (showBid.value) showBid.value = false;
      proxy.value.openSetProxy(id.value);
    };

    const countDownFinish = () => {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      setTimeout(() => {
        loadDetail();
        Toast.clear();
      }, 1500);
    };

    const loadMoreAction = () => {
      recordPage.value += 1;
      loadRecord();
    };

    const hideModalAction = () => {
      showModal.value = false;
    };

    const is_follow_official_account = computed(() => {
      return store.getters.is_follow_official_account;
    });
    const qrcode_url = computed(() => {
      return store.getters.qrcode_url;
    });

    const auctionHeaderShow = ref(false);

    // useEventListener("scroll", () => {
    //   let scrollTop =
    //     window.scrollY ||
    //     window.pageYOffset ||
    //     document.documentElement.scrollTop ||
    //     document.body.scrollTop;
    //   if (scrollTop > 80 && !auctionHeaderShow.value) {
    //     auctionHeaderShow.value = true;
    //   } else if (scrollTop < 80 && auctionHeaderShow.value) {
    //     auctionHeaderShow.value = false;
    //   }
    // });

    let scrollTop = ref(0);
    const scrollBox = ref(null);
    const handleScroll = () => {
      scrollTop.value = scrollBox.value.scrollTop;
      if (scrollTop.value > 80 && !auctionHeaderShow.value) {
        auctionHeaderShow.value = true;
      } else if (scrollTop.value < 80 && auctionHeaderShow.value) {
        auctionHeaderShow.value = false;
      }
    }
    const loadingr = ref(false);
    const onRefresh = () => {
      loadDetail();
      setTimeout(() => {
        loadingr.value = false;
      }, 1000);
    }
    return {
      id,
      auctionData,
      pageStyleData,
      pageContentData,
      showBid,
      recordList,
      recordTotal,
      sdata,

      headerStyle,
      pageStyle,

      watchIndex,
      isPayBond,
      showPayBondMoney,

      recommendList,
      listFacade,
      account,
      showBidRange,
      leftBtnStyle,
      rightBtnStyle,
      proxy,
      compel_anonymous,
      showModal,
      order_timeout,
      is_follow_official_account,
      qrcode_url,

      showBidAction,
      closeAction,
      getNumberPlateAction,
      backAction,
      sendData,
      showPayBondMoneyAction,
      payOk,
      payClear,
      bidOk,
      formatPriceNumber,
      hideBidRangeAction,
      showBidRangeAction,
      proxyBidAction,
      countDownFinish,
      loadMoreAction,
      hideModalAction,

      auctionHeaderShow,

      scrollBox,
      handleScroll,
      scrollTop,
      loadingr,
      onRefresh,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100vw;
  min-height: 100vh;
  background-position: center;
  background-attachment: fixed;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding-bottom: 180px;
}

.detail-header {
  position: relative;
  .back {
    width: 48px;
    height: 48px;
    position: absolute;
    left: 14px;
    top: 16px;

    img {
      width: 48px;
      height: 48px;
    }
  }
}

.price-family {
  font-family: NUM;
}

.list {
  padding-bottom: 20px;
}
.range-view {
  padding-top: 30px;
  .jjjt {
    height: 70px;
    font-size: 26px;
    border-bottom: 2px dashed #ededed;

    div {
      flex: 1;
    }

    .l {
      margin-left: 50px;
    }

    .r {
      margin-right: 50px;
      text-align: right;
      font-family: NUM;
    }
  }
}

.czts-title {
  padding: 30px 30px 0 30px;
  font-size: 32px;
  font-weight: 500;
}
.czts-c-view {
  padding: 30px;
}
.czts-btn {
  margin: 0 30px;
  padding-top: 20px;
  border-top: 2px solid #f7f7f7;
  color: #ae0000;
  font-size: 30px;
  line-height: 60px;
  padding-bottom: 20px;
}

.album-header {
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 100;

  .album-header-t {
    height: 88px;
    .back {
      margin-left: 32px;
      height: 88px;
      img {
        width: 48px;
        height: 48px;
        display: block;
      }
    }

    .n {
      font-size: 32px;
      margin-right: 32px;
    }
  }

  .album-header-b {
    padding: 10px 32px 20px 32px;
    .search {
      width: 200px;
      height: 56px;
      background: #f5f5f5;
      border-radius: 28px;
      padding: 0 20px;
      box-sizing: border-box;
      z-index: 10;

      input {
        border: none;
        background: none;
      }
      img {
        width: 32px;
        height: 32px;
        display: block;
        margin: 0 10px;
      }
    }
    .search-icon {
      height: 32px;
      height: 32px;
      img {
        height: 32px;
        height: 32px;
        display: block;
      }
    }
  }
}
</style>