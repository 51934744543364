<template>
  <div
    class="flex align-items-c justify-b"
    v-if="auctionData"
    :style="{ height: (facade.height / 75) + 'rem', padding: `0 ${(pageStyleData.page_margin / 75)}rem`, background: facade.background, fontSize: (facade.fontSize / 75) + 'rem', fontWeight: facade.fontWeight }"
  >
    <div
      class="time1 flex align-items-c ft12"
      :style="{ color: facade.timeColor }"
    >
      <AuctionStatus
        :status="facade.status_type"
        :type="auctionData.auction_status"
        :album_status="auctionData.album && auctionData.album.album_type == 3 ? auctionData.album.album_status : ''"
        sty="display: inline-block;position: relative; margin-right: 0.0666rem;"
      />
      <template v-if="auctionData.auction_status > 2">
        <div class="ml5">{{endTime[0]}}年{{endTime[1]}}月{{endTime[2]}}日 {{endTime[3]}}:{{endTime[4]}}</div>
        <div class="ml5">结拍</div>
      </template>
      <template v-else-if="auctionData.goTime > 0">
        <van-count-down
          :time="auctionData.goTime * 1000"
          @finish="onFinish"
        >
          <template #default="timeData">
            <template v-if="timeData.total / 1000 > 21600">
              <div
                class="flex align-items-c"
                :style="{ fontSize: (facade.fontSize / 75) + 'rem', fontWeight: facade.fontWeight, color: facade.timeColor }"
              >
                <template v-if="auctionData.auction_status == 1">
                  <div
                    class="bg ml5"
                    :style="{ color: facade.activeColor }"
                  >{{ endTime[1] }}</div>
                  <div>月</div>
                  <div
                    class="bg"
                    :style="{ color: facade.activeColor }"
                  >{{ endTime[2] }}</div>
                  <div>日</div>
                  <div
                    class="bg"
                    :style="{ color: facade.activeColor }"
                  >{{ endTime[3] }}:{{ endTime[4] }}</div>
                  <div class="ml5">结拍</div>
                </template>
                <template v-else-if="auctionData.auction_status == 2">
                  <div
                    class="bg ml5"
                    :style="{ color: facade.activeColor }"
                  >{{ startTime[1] }}</div>
                  <div>月</div>
                  <div
                    class="bg"
                    :style="{ color: facade.activeColor }"
                  >{{ startTime[2] }}</div>
                  <div>日</div>
                  <div
                    class="bg"
                    :style="{ color: facade.activeColor }"
                  >{{ startTime[3] }}:{{ startTime[4] }}</div>
                  <div class="ml5">开拍</div>
                </template>
              </div>
            </template>
            <template v-else>
              <div
                class="flex align-items-c"
                :style="{ fontSize: (facade.fontSize / 75) + 'rem', fontWeight: facade.fontWeight, color: facade.timeColor }"
              >
                <div class="ml5 mr5">{{ auctionData.auction_status == 1 ? '距结拍' : '距开拍' }}</div>
                <template v-if="timeData.hours">
                  <div
                    :class="facade.time_type == 1 ? 'bg1' : 'bg'"
                    :style="timeStyle"
                  >{{ timeData.hours }}</div>
                  <div>时</div>
                </template>
                <div
                  :class="facade.time_type == 1 ? 'bg1' : 'bg'"
                  :style="timeStyle"
                >{{ timeData.minutes }}</div>
                <div>分</div>
                <div
                  :class="facade.time_type == 1 ? 'bg1' : 'bg'"
                  :style="timeStyle"
                >{{ timeData.seconds }}</div>
                <div>秒</div>
              </div>
            </template>
          </template>
        </van-count-down>
      </template>
    </div>
    <div
      v-if="auctionData.auction_status == 1 || auctionData.auction_status == 2"
      class="r-jd-btn flex align-items-c justify-c"
      :style="{ color: facade.color }"
      @click="likeAction"
    >
      <img src="https://artimg2.artart.cn/image/20220416/cd5ff7d602654419c54bc945f5595ffa.png">
      <div v-if="auctionData.auction_status == 1">{{ auctionData.isCare ? '取消' : '结拍' }}提醒</div>
      <div v-else>{{ auctionData.isCare ? '取消' : '开拍' }}提醒</div>
    </div>
  </div>
</template>

<script>
import { computed, toRefs } from "vue";
import { CountDown, Toast } from "vant";
import { useStore } from "vuex";
import { parseTime } from "@/utils/util";
import { relationCreate, relationDel } from "@/api/user";
import AuctionStatus from "@/components/DiyComponent/AuctionStatus";

export default {
  components: {
    [CountDown.name]: CountDown,
    AuctionStatus,
  },
  props: {
    auctionData: [String, Object],
    facade: Object,
    pageStyleData: Object,
  },
  setup(props, { emit }) {
    const { auctionData, facade } = toRefs(props);
    const store = useStore();
    // 右按钮样式
    const rightBtnStyle = computed(() => {
      return store.getters.right_button_style
        ? store.getters.right_button_style
        : {};
    });

    const endTime = computed(() => {
      return auctionData.value && auctionData.value.end_time
        ? parseTime(auctionData.value.end_time, "{y},{m},{d},{h},{i}").split(
            ","
          )
        : [];
    });
    const startTime = computed(() => {
      return auctionData.value && auctionData.value.start_time
        ? parseTime(auctionData.value.start_time, "{y},{m},{d},{h},{i}").split(
            ","
          )
        : [];
    });
    const timeStyle = computed(() => {
      if (facade.value.time_type == 1) {
        return `background: ${facade.value.bgBtnColor};color: ${facade.value.bgColor};`;
      } else {
        return `color: ${facade.value.activeColor};margin: 0;padding: 0;`;
      }
    });

    const likeAction = () => {
      let action = relationCreate;
      const data = {
        type_id: auctionData.value.product_id,
        type_ids: auctionData.value.product_id,
        type: 11,
      };
      if (auctionData.value.isCare) {
        action = relationDel;
      }
      action(data)
        .then(() => {
          auctionData.value.isCare = auctionData.value.isCare == 1 ? 0 : 1;
        })
        .catch((message) => Toast(message));
    };

    const onFinish = () => {
      // 倒计时结束
      emit("countDownFinish");
    };

    return {
      rightBtnStyle,
      endTime,
      startTime,
      timeStyle,

      likeAction,
      onFinish,
    };
  },
};
</script>

<style lang="scss" scoped>
.time1 {
  .bg {
    text-align: center;
    font-weight: 500;
    box-sizing: border-box;
  }
  .bg1 {
    min-width: 36px;
    height: 36px;
    text-align: center;
    padding: 2px 8px;
    margin: 0 6px;
    border-radius: 4px;
    font-weight: 400;
    line-height: 32px;
    font-size: 28px;
    box-sizing: border-box;
  }

  .item {
    display: flex;
    align-items: center;
    margin-left: 10px;
    span {
      color: #cd2c2c;
      font-size: 32px;
      font-weight: 500;
      margin-right: 10px;
    }
  }
}

.r-btn {
  img {
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }
}

.r-jd-btn {
  height: 40px;

  img {
    width: 32px;
    height: 32px;
    margin-right: 4px;
  }
}
</style>