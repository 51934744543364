<template>
  <div
    class="detail-img item-flex-center"
    :style="{ marginTop: `${(facade.marginTop / 75)}rem`, background: facade.background, width: (facade.view_width / 75) + 'rem', height: (facade.view_height / 75) + 'rem' }"
    v-if="auctionData"
  >
    <div style="position: relative;">
      <van-swipe
        :style="{ width: (facade.img_width / 75) + 'rem', height: (facade.img_height / 75) + 'rem' }"
        class="banner-swiper"
        :autoplay="5000"
        indicator-color="white"
        :loop="true"
        :show-indicators="false"
        @change="changeSwiper"
        :stop-propagation="false"
        @touchmove.stop
      >
        <van-swipe-item
          v-for="(item, index) in auctionData.product.slider_image"
          :key="index"
        >
          <div
            class="swiper-img"
            :style="{
              borderTopLeftRadius: `${facade.img_top_radius / 75}rem`,
              borderTopRightRadius: `${facade.img_top_radius / 75}rem`,
              borderBottomLeftRadius: `${facade.img_bottom_radius / 75}rem`,
              borderBottomRightRadius: `${facade.img_bottom_radius / 75}rem`,
              overflow: 'hidden'
            }"
            @click="changeImage(auctionData.product.slider_image, index)"
          >
            <van-image
              :style="{ width: (facade.img_width / 75) + 'rem', height: (facade.img_height / 75) + 'rem' }"
              :src="item + '!m640'"
              fit="contain"
              v-lazy="item"
            />
          </div>
        </van-swipe-item>
      </van-swipe>
      <Pointer
        vAlign="top"
        :align="facade.indicator_align"
        :type="facade.indicator_style"
        :color="facade.indicator_color"
        :pointerCount="auctionData.product.slider_image.length"
        :curr="current"
      />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Pointer from "@/components/DiyComponent/Pointer";
import { Swipe, SwipeItem, ImagePreview, Image as VanImage } from "vant";
export default {
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [VanImage.name]: VanImage,
    Pointer,
  },
  props: {
    auctionData: [String, Object],
    facade: Object,
    pageInfo: Object,
  },
  setup() {
    const current = ref(0);

    // 更改轮播图选中索引
    const changeSwiper = (index) => (current.value = index);
    const changeImage = (list, index) => {
      ImagePreview({
        images: list,
        startPosition: index,
      });
    };

    return {
      current,

      changeSwiper,
      changeImage,
    };
  },
};
</script>

<style lang="scss" scoped>
.swiper-img {
  .van-image{
    display: block !important;
  }
  >>> img{
    display: block;
  }
}
</style>