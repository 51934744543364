<template>
  <div
    class="album_footer"
    v-if="auctionData"
  >
    <div
      class="bid-top flex align-items-c"
      v-if="(auctionData.auction_status == 1 || auctionData.auction_status == 2) && ((auctionData.album && auctionData.album.bond_type == 2 && userNumber) || (userNumberType == '免保号牌' || userNumberType == '特殊号牌') || (userNumber && auctionData.album && auctionData.album.live_id))"
    >
      <div class="number flex-one">号牌 <span class="fw500 color-black">{{ userNumber }}</span> <span
          class="vip"
          v-if="userNumberType == '免保号牌' || userNumberType == '特殊号牌'"
        >VIP免保证金</span>
        <template v-else-if="auctionData.album && auctionData.album.bond_type == 2">参拍额度 <span class="fw500 color-black">{{ formatPriceNumber(account * auctionData.album.bond_bid_ratio, 2) }}</span></template>
      </div>
      <div
        v-if="auctionData.album && auctionData.album.bond_type == 2"
        class="cz"
        @click="goAtherPage('/users/recharge')"
      >
        <div>充值</div>
        <img src="https://artimg2.artart.cn/image/20220416/e361ec375b10cb2e2b2475707181f47d.png">
      </div>
    </div>
    <div class="flex align-items-e pr20">
      <div
        class="flex align-items-c"
        style="margin-right: 0.42666rem;"
      >
        <div
          class="icon-img flex align-items-c justify-c"
          style="margin-left: 0.42666rem;"
        >
          <img src="https://artimg2.artart.cn/image/20220108/9e1bf2c2f79de4fd542675f534f0910f.png">
        </div>
        <div
          class="icon-img flex align-items-c justify-c"
          style="margin-left: 0.21333rem;"
          @click="shareAction"
        >
          <img src="https://artimg2.artart.cn/image/20220108/8991f37810e6699b8e062a13b7336ce1.png">
        </div>
      </div>
      <div
        class="flex-one"
        v-if="auctionData.auction_status == 1 || auctionData.auction_status == 2"
      >
        <div class="flex align-items-c">
          <div
            v-if="userNumber && userNumberType != '免保号牌' && userNumberType != '特殊号牌' && !(auctionData.album && auctionData.album.live_id) && (!auctionData.album || (auctionData.album && auctionData.album.bond_type == 1))"
            class="ft14 color-gray mr20"
          >号牌 <span class="fw500 color-black">{{ userNumber }}</span></div>
          <div
            v-if="(!auctionData.album || (auctionData.album && auctionData.album.bond_type == 1)) && ((auctionData.album && auctionData.album.bond_price) || auctionData.bond_price) && !isPayBond"
            class="bottom-btn l"
            :style="(auctionData.album && auctionData.album.live_id ? leftBtnStyle : rightBtnStyle)"
            @click="payBondAction"
          >
            <div>交保证金</div>
          </div>
          <div
            v-else-if="!userNumber"
            class="bottom-btn l"
            :style="(auctionData.album && auctionData.album.live_id ? leftBtnStyle : rightBtnStyle)"
            @click="useNumberPlateAction"
          >领取号牌</div>
          <template v-else-if="auctionData.auction_status == 2">
            <div
              class="bottom-btn l"
              :style="(auctionData.album && auctionData.album.live_id ? leftBtnStyle : rightBtnStyle)"
              @click="proxyBidAction"
            >委托出价</div>
          </template>
          <template v-else-if="auctionData.auction_status == 1">
            <div
              class="bottom-btn l"
              :style="(auctionData.album && auctionData.album.live_id ? leftBtnStyle : rightBtnStyle)"
              @click="bidAction"
            >立即出价</div>
          </template>
          <div
            v-if="auctionData.album && auctionData.album.live_id"
            class="bottom-btn l"
            :style="rightBtnStyle"
            @click="goAtherPage('/pages/Live/Live?id=' + auctionData.album.live_id+'&merchantid=' + auctionData.mer_id)"
          >进入{{ auctionData.album.album_type == 1 ? '直播间' : '拍场' }}</div>
        </div>
      </div>
      <div
        class="flex-one flex align-items-c"
        v-else-if="auctionData.auction_status == 3 && auctionData.isMy == 1"
      >
        <div
          v-if="userNumber"
          class="ft14 color-gray"
        >保留价 <span class="fw500 color-black mr20">{{ auctionData.retain_price }}</span></div>
        <div
          class="bottom-btn l"
          :style="rightBtnStyle"
          @click="confirmRetainPriceAction(auctionData.product_id, formatPriceNumber(auctionData.retain_price, 2))"
        >加一口</div>
      </div>
      <div
        class="flex-one flex align-items-c"
        v-else-if="auctionData.auction_status == 4 && auctionData.hasQiagou"
      >
        <div
          class="bottom-btn l"
          :style="rightBtnStyle"
        >洽购</div>
      </div>
      <div
        class="flex-one flex align-items-c"
        v-else-if="auctionData.auction_status == 5 && auctionData.isMy == 1"
      >
        <div
          class="bottom-btn l"
          :style="rightBtnStyle"
          @click="goPayAction"
        >去支付</div>
      </div>
      <div
        class="flex-one flex align-items-c"
        v-else-if="auctionData.auction_status == 6 && auctionData.isMy == 1"
      >
        <div
          class="bottom-btn l"
          :style="rightBtnStyle"
        >查看订单</div>
      </div>
      <div
        class="flex-one flex align-items-c"
        v-else
      >
        <div
          class="bottom-btn l hui"
          :style="rightBtnStyle"
        >已结束</div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, toRefs } from "vue";
import { useRouter } from "vue-router";
import { Toast, ImagePreview, Dialog } from "vant";
import { userNumberPlateHandle, confirmRetainPriceApi } from "@/api/auction";
import { formatPriceNumber } from "@/utils/util";
import { sharePosterImg } from "@/api/system";

export default {
  props: {
    auctionData: [String, Object],
    isPayBond: Boolean,
    merchant:Object,
  },
  setup(props, content) {
    const { auctionData } = toRefs(props);
    const store = useStore();
    const router = useRouter();

    // 获取button样式集合
    const colorData = computed(() => {
      return store.getters.color_data ? store.getters.color_data : {};
    });
    // 右按钮样式
    const rightBtnStyle = computed(() => {
      return store.getters.right_button_style
        ? store.getters.right_button_style
        : {};
    });
    // 左按钮样式
    const leftBtnStyle = computed(() => {
      return store.getters.left_button_style
        ? store.getters.left_button_style
        : {};
    });

    // 获取号牌
    const userNumber = computed(() => {
      return auctionData.value.numberPlate &&
        auctionData.value.numberPlate.number
        ? auctionData.value.numberPlate.number
        : "";
    });

    // 获取号牌
    const userNumberType = computed(() => {
      return auctionData.value.numberPlate &&
        auctionData.value.numberPlate.number_type
        ? auctionData.value.numberPlate.number_type
        : "";
    });

    const bidAction = () => {
      content.emit("bid");
    };

    const useNumberPlateAction = () => {
      // 办理号牌
      if (
        auctionData.value.album &&
        auctionData.value.album.album_config.check_user_idCard == 2
      ) {
        store
          .dispatch("user/applyVerifyInfo")
          .then((type) => {
            if (type == 1 || type == 2) {
              userNumberAction();
            }
          })
          .catch((message) => Toast(message));
        return false;
      }
      userNumberAction();
    };

    const userNumberAction = () => {
      userNumberPlateHandle({
        venue_id: auctionData.value.venue_id,
      })
        .then((res) => {
          content.emit("userNumberPlate", res.data);
        })
        .catch((message) => Toast(message));
    };

    const payBondAction = () => {
      if (!userNumber.value) {
        useNumberPlateAction();
      }
      content.emit("showPayBondMoney");
    };

    const proxyBidAction = () => {
      // 代理出价
      content.emit("proxyBidAction");
    };

    const goAtherPage = (link) => {
      if (link) {
        router.push(link);
      }
    };

    const goPayAction = () => {
      if (auctionData.value.orderInfo) {
        router.push({
          path: "/pages/orderConfirm/index",
          query: { order_id: auctionData.value.orderInfo.order_id },
        });
      }
    };

    const account = computed(() => {
      return store.getters.account.balance;
    });

    const shareAction = () => {
      const data = {
        poster_type: "auction",
        qrcode_type: 1,
        Independent_qrcode: 0,
        id: auctionData.value.product_id,
        return_format: 1,
        inviteuid: store.getters.uid,
      };
      ImagePreview([sharePosterImg(data)]);
    };

    const merchantInfo = computed(() => {
      if(props.merchant){
        return props.merchant
      }
      return store.getters.merchantInfo ? store.getters.merchantInfo : {};
    });

    const confirmRetainPriceAction = (id, price) => {
      Dialog.alert({
        message: "确认出价" + price + merchantInfo.value.fee_type_text + '?',
        confirmButtonText: "确认出价",
        showCancelButton: true,
        cancelButtonText: '取消'
      }).then(() => {
        confirmRetainPriceApi({
          auction_id: id,
        }).catch((message) => Toast(message));
      }).catch(() => {});
    };

    return {
      rightBtnStyle,
      leftBtnStyle,
      userNumber,
      userNumberType,
      account,

      colorData,
      bidAction,
      useNumberPlateAction,
      payBondAction,
      proxyBidAction,
      goAtherPage,
      goPayAction,
      formatPriceNumber,
      shareAction,
      confirmRetainPriceAction
    };
  },
};
</script>

<style lang="scss" scoped>
.album_footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  font-size: 18px;
  padding: 16px 0;
  color: #000;
  background: #fff;
  padding-bottom: 40px;
  box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.03);
  .icon-img {
    width: 80px;
    height: 80px;
    img {
      width: 48px;
      height: 48px;
    }
  }
  .icon-img1 {
    height: 80px;
    margin-top: 16px;
    img {
      width: 48px;
      height: 48px;
    }
  }

  .bottom-btn {
    flex: 1;
    height: 80px;
    line-height: 72px;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    &.hui {
      background: #eee !important;
      border-color: #eee !important;
      color: #ccc !important;
    }

    &.l.no-radius {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    &.r.no-radius {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    img {
      width: 32px;
      height: 32px;
      display: block;
      margin-right: 8px;
    }
  }

  .bottom-txt,
  .bottom-a {
    font-size: 26px;
    margin-right: 20px;
    padding-left: 30px;

    &.pl0 {
      padding-left: 0;
    }

    span {
      font-weight: 500;
    }
  }
  .icon {
    margin-left: 30px;
    margin-right: 8px;
    display: block;
    width: 48px;
    height: 48px;
  }

  .bottom-a {
    color: #347ff4;
  }
}

.relative {
  position: relative;
}
.bg-white {
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.pr20 {
  padding-right: 20px;
}

.bid-top {
  padding: 0 32px 20px 32px;
  font-size: 28px;
  color: #666;
  .number {
    margin-right: 40px;

    span {
      font-family: NUM;
      color: #000;
    }

    .vip {
      color: #c1955c;
      margin-left: 20px;
    }
  }
  .cz {
    color: #347ff4;
    font-weight: 500;
  }
}

.bid-top .cz {
  color: #347ff4;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.bid-top .cz img {
  width: 32px;
  height: 32px;
  display: block;
}
</style>