<template>
  <div>
    <div
      v-if="auctionData && auctionData.product && auctionData.product.store_info"
      :style="{ padding: `${(facade.marginTop / 75)}rem ${(pageInfo.page_margin / 75)}rem 0 ${(pageInfo.page_margin / 75)}rem`, background: facade.background }"
    >
      <div
        :style="{
          color: facade.color,
          fontWeight: facade.fontWeight,
          fontSize: (facade.fontSize / 75) + 'rem'
        }"
        :class="facade.fold ? 'line1' : 'line2'"
      >
        {{ auctionData.product.store_info }}
      </div>
    </div>
    <div
      v-else-if="auctionData && auctionData.attr_info && auctionData.attr_info.length > 0"
      :style="{ padding: `${(facade.marginTop / 75)}rem ${(pageInfo.page_margin / 75)}rem 0 ${(pageInfo.page_margin / 75)}rem`, background: facade.background }"
    >
      <div
        :style="{
          color: facade.color,
          fontWeight: facade.fontWeight,
          fontSize: (facade.fontSize / 75) + 'rem'
        }"
        class="qz1"
        :class="facade.fold ? 'line1' : 'line2'"
      >
        <template
          v-for="(item, index) in auctionData.attr_info"
          :key="index"
        >
          <template v-if="index > 0"> | </template>{{item.attr_name}}:{{item.attr_value}}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    auctionData: [String, Object],
    facade: Object,
    pageInfo: Object,
  },
  setup() {},
};
</script>

<style lang="scss" scoped>
.line2{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 34px;
  max-height: 68px;
}
</style>