<template>
  <div
    class="bid-price"
    v-show="showBid"
    @click="closeBidAction"
  >
    <div
      class="common-bid-price"
      @click.stop="kong"
    >
      <div class="common-title flex align-items-c fw500">
        <div class="flex align-items-c flex-one">
          <img
            v-if="userInfo.avatar"
            :src="userInfo.avatar"
            alt=""
          >
          <div class="ft12">{{ userInfo.uname }}</div>
        </div>
        <div
          v-if="auctionDetail.auction_type != 3"
          class="anonymous flex align-items-c"
          @click="changeAnonymous"
        >
          <img
            v-if="is_anonymous"
            :style="`background: ${colorData.specialColor}`"
            src="https://artimg2.artart.cn/image/20220310/a4b8853a51378286e440f2a9c2ccabb4.png"
            alt=""
          >
          <img
            v-else
            :style="`background: ${colorData.specialColor}`"
            src="https://artimg2.artart.cn/image/20220310/25d21db375f3b675138697e3b7e0224b.png"
            alt=""
          >
          <div class="ft14 fw500">使用号牌出价</div>
        </div>
        <div
          class="close"
          @click="closeBidAction"
        >
          <img
            src="https://artimg2.artart.cn/image/20220129/37face345e08861294c4efb7895d443e.png"
            alt=""
          >
        </div>
      </div>
      <div
        class="p-bid item-flex-center"
        @click.stop="proxyBidAction"
      >
        <img
          class="wh"
          src="https://artimg2.artart.cn/image/20220319/3ce2945ad242bbe6bb739f4f7891c512.png"
          @click.stop="showMesAction"
        >
        <div class="ml2">设置委托代理出价</div>
        <img
          style="margin: 0;"
          src="https://artimg2.artart.cn/image/20220105/c4a32792718b472c47d4b5dcab146abe.png"
        >
      </div>
      <div
        class="now-price item-flex-center"
        v-if="auctionDetail.now_price || auctionDetail.start_price"
      >{{ !auctionDetail.now_price ? '起拍价' : '领先价' }}<AmountSymbol
          class="ml10"
          :style="{ color: colorData.priceColor }"
          :price="!auctionDetail.now_price ? auctionDetail.start_price : auctionDetail.now_price"
          type="2"
        ></AmountSymbol>

        <!-- <span :style="{ color: colorData.priceColor }">￥</span><span
          class="p"
          :style="{ color: colorData.priceColor }"
        >{{ formatPriceNumber(Number(!auctionDetail.now_price ? auctionDetail.start_price : auctionDetail.now_price)) }}</span> -->
      </div>
      <div
        class="now-price"
        v-else
      ><span
          class="p"
          :style="{ color: colorData.priceColor }"
        >无底价</span></div>
      <div class="btns">
        <div class="btn-two flex align-items-c">
          <div
            class="img item-flex-center"
            @click="jianAction"
          >
            <img
              src="https://artimg2.artart.cn/image/20220129/b5c78222fbec750a0f23a75f37d221e3.png"
              alt=""
            >
          </div>
          <div
            class="btn item-flex-center"
            :style="rightBtnStyle"
            @click="bidAction"
          >
            <div>{{ auctionDetail.now_price ? '加' : '出' }}</div>
            <van-swipe
              ref="vanSwiper"
              class="bid-price-list"
              :duration="200"
              :loop="false"
              :show-indicators="false"
              :vertical="true"
              :touchable="false"
              :stop-propagation="false"
            >
              <van-swipe-item
                v-for="(m, i) in bidPriceList"
                :key="`bid${i}`"
              >
                <div class="bid-p">{{ m.bidNextPriceCha }}</div>
              </van-swipe-item>
            </van-swipe>
          </div>
          <div
            class="img item-flex-center"
            @click="jiaAction"
          >
            <img
              src="https://artimg2.artart.cn/image/20220129/ca1155379c7f36d1c65782c9cada589e.png"
              alt=""
            >
          </div>
        </div>
        <div class="txt ft12 color-gray" @click="goRuleShow">出价即表示同意《{{ mer_name }}交易服务隐私协议》</div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, toRefs, computed, watch } from "vue";
import { formatPriceNumber } from "@/utils/util";
import { Swipe, SwipeItem, Toast, Dialog } from "vant";
import { auctionBidPrice } from "@/api/auction";
import { useRouter } from 'vue-router';

export default {
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  props: {
    cjList: {
      type: Array,
      default: [],
    },
    auctionDetail: {
      type: [Object, String],
      default: {},
    },
    watchIndex: Number,
    showBid: Boolean,
    compel_anonymous: Number,
  },
  setup(props, content) {
    const router = useRouter();
    const store = useStore();
    const { cjList, auctionDetail, watchIndex, compel_anonymous } =
      toRefs(props);

    // 获取color样式集合
    const colorData = computed(() => {
      return store.getters.color_data ? store.getters.color_data : {};
    });
    // 右按钮样式
    const rightBtnStyle = computed(() => {
      return store.getters.right_button_style
        ? store.getters.right_button_style
        : {};
    });
    // 左按钮样式
    const leftBtnStyle = computed(() => {
      return store.getters.left_button_style
        ? store.getters.left_button_style
        : {};
    });
    // 用户信息
    const userInfo = computed(() => {
      return {
        uname: store.getters.uname,
        avatar: store.getters.avatar,
      };
    });
    // 商户名称
    const mer_name = computed(() => {
      return store.getters.mer_name;
    });

    const bidPriceList = ref([]);
    const priceIndex = ref(0);
    const lock = ref(false);
    const upBid = ref(null);

    const vanSwiper = ref(null);

    const priceData = ref([{ min: 0, max: "∞", price: 100000, type: 1 }]);

    const getPriceArea = (price) => {
      const data = priceData.value;
      let index = null;
      for (let i in data) {
        const m = data[i];
        if (price >= m.min && price < m.max) {
          index = i;
          break;
        }
      }
      if (index === null) {
        index = data.length - 1;
      }
      return data[index];
    };

    const settingPrice = (price, refresh) => {
      price = Number(price);
      const area = getPriceArea(price);
      let p = 0;
      switch (area.type) {
        case 1:
          p = price + area.price;
          break;
        case 2:
          p = getBidMoney258(price);
          break;
        case 3:
          p = getBidMoneyThree258(price);
          break;
        default:
          p = getBidMoney258(price);
          break;
      }

      // bidNextPrice.value = p;
      setPrice(
        {
          bidNextPrice: p,
          bidNextPriceCha: formatPriceNumber(
            p -
              (cjList.value[0]
                ? cjList.value[0].allcoin
                : Number(auctionDetail.value.now_price)),
            2
          ),
        },
        refresh
      );
    };
    const setPrice = (data, refresh) => {
      bidPriceList.value = refresh ? [data] : bidPriceList.value.concat([data]);
      if (priceIndex.value == -1) {
        lock.value = false;
      } else {
        priceIndex.value = bidPriceList.value.length - 1;
        vanSwiper.value.swipeTo(priceIndex.value);
        if (upBid.value) clearTimeout(upBid.value);
        upBid.value = setTimeout(() => {
          lock.value = false;
        }, 300);
      }
    };
    const getBidMoney258 = (price) => {
      const money = price.toString();
      let numCount = money.length;

      let firstNum = Number(money.substr(0, 1));
      let secondNum = money.substr(1, 1);

      if (numCount <= 2) {
        return 200;
      } else if (numCount <= 3) {
        if (firstNum < 2) {
          return 200;
        } else if (firstNum < 5) {
          return 500;
        } else if (firstNum < 8) {
          return 800;
        } else {
          return 1000;
        }
      }

      if (firstNum && secondNum == 0) {
        secondNum = 2;
      } else {
        if (secondNum < 2) {
          secondNum = 2;
        } else if (secondNum < 5) {
          secondNum = 5;
        } else if (secondNum < 8) {
          secondNum = 8;
        } else {
          firstNum += 1;
          secondNum = 0;
          if (`${firstNum}`.length > 1) {
            numCount += `${firstNum}`.length - 1;
          }
        }
      }
      return Number(prefixZero(`${firstNum}${secondNum}`, numCount));
    };
    const getBidMoneyThree258 = (price) => {
      const money = price.toString();
      const numCount = money.length;
      if (numCount < 5) {
        return getBidMoney258(price);
      }

      let firstNum = Number(money.substr(0, 1));
      let secondNum = money.substr(1, 1);
      let threeNum = money.substr(2, 1);

      if (threeNum < 2) {
        threeNum = Number(prefixZero(2, numCount - 2));
      } else if (threeNum < 5) {
        threeNum = Number(prefixZero(5, numCount - 2));
      } else if (threeNum < 8) {
        threeNum = Number(prefixZero(8, numCount - 2));
      } else {
        threeNum = Number(prefixZero(1, numCount - 1));
      }

      return Number(prefixZero(`${firstNum}${secondNum}`, numCount)) + threeNum;
    };
    const prefixZero = (num, n) => {
      return (num + Array(n).join(0)).slice(0, n);
    };

    const init = (newVal) => {
      if (newVal.bid_range_id) {
        if (newVal.bidRange) {
          priceData.value = newVal.bidRange.content;
        }
      } else {
        priceData.value = [
          { min: 0, max: "∞", price: Number(newVal.plus_price), type: 1 },
        ];
      }
      if (newVal && newVal.product_id) {
        if (newVal.now_price) {
          settingPrice(newVal.now_price, 2);
        } else {
          if (upBid.value) clearTimeout(upBid.value);
          lock.value = true;
          if (newVal.start_price) {
            setPrice(
              {
                bidNextPrice: Number(newVal.start_price),
                bidNextPriceCha: formatPriceNumber(
                  Number(newVal.start_price) - Number(newVal.now_price),
                  2
                ),
              },
              1
            );
          } else {
            settingPrice(0, 1);
          }
        }
      }
    };

    // watch(auctionDetail, (newVal) => {
    //   init(newVal);
    // });

    watch(watchIndex, () => {
      if (auctionDetail.value.userLastBidRecord) {
        is_anonymous.value = auctionDetail.value.userLastBidRecord.is_anonymous;
      }
      init(auctionDetail.value);
    });

    const jiaAction = () => {
      if (lock.value) return false;
      lock.value = true;
      if (priceIndex.value + 1 >= bidPriceList.value.length) {
        settingPrice(bidPriceList.value[priceIndex.value].bidNextPrice);
      } else {
        priceIndex.value = priceIndex.value + 1;
        vanSwiper.value.swipeTo(priceIndex.value);
        if (upBid.value) clearTimeout(upBid.value);
        upBid.value = setTimeout(() => {
          lock.value = false;
        }, 300);
      }
    };

    const jianAction = () => {
      if (priceIndex.value > 0) {
        if (lock.value) return false;
        lock.value = true;
        priceIndex.value = priceIndex.value - 1;
        vanSwiper.value.swipeTo(priceIndex.value);
        if (upBid.value) clearTimeout(upBid.value);
        upBid.value = setTimeout(() => {
          lock.value = false;
        }, 300);
      }
    };

    let bidLock = false;

    const bidAction = () => {
      if (bidLock) return false;
      bidLock = true;
      const money_bid = bidPriceList.value[priceIndex.value].bidNextPrice;
      const auction_id = auctionDetail.value.product_id;
      auctionBidPrice({
        bid_price: money_bid,
        auction_id: auction_id,
        is_anonymous: is_anonymous.value,
      })
        .then(() => {
          bidLock = false;
          content.emit("ok");
        })
        .catch((message) => {
          Toast(message);
          bidLock = false;
        });
    };

    const closeBidAction = () => {
      content.emit("close");
    };
    const proxyBidAction = () => {
      // 代理出价
      content.emit("proxyBidAction");
    };

    const kong = () => {};

    const is_anonymous = ref(1);
    const changeAnonymous = () => {
      if (auctionDetail.value.userLastBidRecord) {
        is_anonymous.value = auctionDetail.value.userLastBidRecord.is_anonymous;
      } else if (compel_anonymous.value === 1) {
        is_anonymous.value = 1;
      } else {
        is_anonymous.value = Number(!is_anonymous.value);
      }
    };

    const showMesAction = () => {
      Dialog.alert({
        message: "设置委托价格金额后，当拍卖价格达到该委托价格上一口价时将自动出价。",
        confirmButtonText: '我知道了'
      });
    };

    const goRuleShow = () => {
      // 查看协议
      router.push('/users/agreement?type=2');
    }

    return {
      vanSwiper,
      colorData,
      rightBtnStyle,
      leftBtnStyle,
      userInfo,

      bidPriceList,
      priceIndex,
      is_anonymous,
      mer_name,

      formatPriceNumber,
      jiaAction,
      jianAction,
      bidAction,
      closeBidAction,
      kong,
      proxyBidAction,
      changeAnonymous,
      showMesAction,
      goRuleShow
    };
  },
};
</script>

<style lang="scss" scoped>
.bid-price {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-end;
  z-index: 101;

  .common-bid-price {
    width: 100%;
    background: #fff;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    padding-bottom: 60px;

    .common-title {
      position: relative;
      padding-top: 22px;
      font-size: 32px;

      .anonymous {
        /* width: 130rpx; */
        margin-right: 100px;
      }
      .anonymous img {
        width: 28px;
        height: 28px;
        display: block;
        margin-right: 4px;
        border-radius: 0;
      }

      img {
        margin-left: 32px;
        width: 60px;
        height: 60px;
        display: block;
        border-radius: 50%;
        margin-right: 16px;
      }

      .close {
        position: absolute;
        right: 0;
        top: 0;
        padding-top: 28px;
        padding-right: 32px;

        img {
          width: 48px;
          height: 48px;
          margin-right: 0;
        }
      }
    }
  }

  .now-price {
    padding-top: 38px;
    text-align: center;
    font-size: 28px;

    span {
      font-weight: 500;

      &.p {
        font-size: 48px;
        font-family: NUM;
      }
    }
  }

  .price-list {
    padding-top: 48px;
    padding-right: 20px;

    .item {
      flex: 1;
      margin-left: 20px;

      height: 80px;
      background: #ffffff;
      border-radius: 8px;
      border: 2px solid #dddddd;
      font-weight: 500;
      font-size: 32px;
      line-height: 80px;
      text-align: center;

      position: relative;

      .bg {
        position: absolute;
        right: 6px;
        top: 6px;
        width: 24px;
        height: 24px;
      }
    }
  }
  .btns {
    padding-top: 34px;
    .btn {
      margin: 0 20px;
      margin-top: 22px;
      line-height: 80px;
      text-align: center;
    }
    .btn-two {
      margin: 0 20px;
      padding-top: 42px;

      .anonymous {
        width: 130px;

        .nochange {
          width: 28px;
          height: 28px;
          display: block;
          margin-right: 4px;
        }
      }
      .img {
        width: 80px;
        height: 80px;
        border-radius: 8px;
        border: 2px solid #eeeeee;
        img {
          width: 48px;
          height: 48px;
          display: block;
        }
      }
      .btn {
        flex: 1;
        margin: 0 16px;
        height: 80px;
        line-height: 80px;
        text-align: center;

        .bid-price-list {
          height: 80px;
        }
      }
    }
    .txt {
      margin: 28px;
      text-align: center;
    }
  }
}
.p-bid {
  padding-top: 58px;
  font-size: 28px;
}
.p-bid img {
  width: 32px;
  height: 32px;
  display: block;
  // margin: 0 8px;
}
</style>