<template>
  <div
    v-if="auctionData"
    :style="{ padding: `0 ${(pageInfo.page_margin / 75)}rem`, background: facade.background, marginTop: (facade.marginTop / 75) + 'rem' }"
  >
    <div :style="{ lineHeight: (pageInfo.tLineHeight / 75) + 'rem', fontSize: (pageInfo.tFontSize / 75) + 'rem', color: pageInfo.tColor, fontWeight: pageInfo.tFontWeight, position: 'relative' }">本场规则
      <div
        class="rule flex align-items-c"
        :style="`line-height: ${facade.lineHeight / 75}rem;font-size: ${facade.fontSize / 75}rem;top: ${10 / 75}rem;font-weight: 400`"
      >
        <span
          v-if="auctionData.bid_type == 2"
          class="link"
          @click="showBidRangeAction"
          style="color: #347ff4;"
        >竞价阶梯</span>
        <span
          class="link ml10"
          @click="goRuleShow"
          style="color: #347ff4;"
        >参拍规则</span>
      </div>
    </div>
    <div class="desc flex align-items-c">
      <div class="params">
        <div
          class="flex align-items-c"
          :style="`line-height: ${facade.lineHeight / 75}rem;font-size: ${facade.fontSize / 75}rem`"
          v-if="auctionData.bond_price"
        >
          <div
            class="l"
            :style="`color: ${facade.color};font-weight: ${facade.fontWeight};`"
          >保证金</div>
          <div
            class="r line1"
            :style="`color: ${facade.otherColor};`"
          >{{ formatPriceNumber(auctionData.bond_price, 2) }}{{ merchantInfo ? merchantInfo.fee_type_text : '' }}</div>
        </div>
        <div
          class="flex align-items-c"
          :style="`line-height: ${facade.lineHeight / 75}rem;font-size: ${facade.fontSize / 75}rem`"
          v-else-if="auctionData.album && auctionData.album.bond_type == 1 && auctionData.album.bond_price"
        >
          <div
            class="l"
            :style="`color: ${facade.color};font-weight: ${facade.fontWeight};`"
          >保证金</div>
          <div
            class="r line1"
            :style="`color: ${facade.otherColor};`"
          >{{ formatPriceNumber(auctionData.album.bond_price, 2) }}{{ merchantInfo ? merchantInfo.fee_type_text : '' }}</div>
        </div>
        <div
          class="flex align-items-c"
          :style="`line-height: ${facade.lineHeight / 75}rem;font-size: ${facade.fontSize / 75}rem`"
          v-else-if="auctionData.album && auctionData.album.bond_type == 2"
        >
          <div
            class="l"
            :style="`color: ${facade.color};font-weight: ${facade.fontWeight};`"
          >保证金</div>
          <div
            class="r line1"
            :style="`color: ${facade.otherColor};`"
          >1:{{ auctionData.album.bond_bid_ratio }}(充值1{{ merchantInfo ? merchantInfo.fee_type_text : '' }}可出价{{1 * auctionData.album.bond_bid_ratio}}{{ merchantInfo ? merchantInfo.fee_type_text : '' }})</div>
        </div>
        <div
          v-else
          class="flex align-items-c"
          :style="`line-height: ${facade.lineHeight / 75}rem;font-size: ${facade.fontSize / 75}rem`"
        >
          <div
            class="l"
            :style="`color: ${facade.color};font-weight: ${facade.fontWeight};`"
          >保证金</div>
          <div
            class="r line1"
            :style="`color: ${facade.otherColor};`"
          >免保证金</div>
        </div>
        <div
          v-if="auctionData.album"
          class="flex align-items-c"
          :style="`line-height: ${facade.lineHeight / 75}rem;font-size: ${facade.fontSize / 75}rem`"
        >
          <div
            class="l"
            :style="`color: ${facade.color};font-weight: ${facade.fontWeight};`"
          >佣金</div>
          <div
            class="r line1"
            :style="`color: ${facade.otherColor};`"
          >{{ auctionData.album.commission_config_json.commission }}%<template v-if="auctionData.album.commission_config_json.after_commission">（{{ parseTime(auctionData.album.commission_config_json.commission_time, '{y}年{m}月{d}日') }}以后{{ auctionData.album.commission_config_json.after_commission }}%）</template></div>
        </div>
        <div
          v-if="auctionData.product.postage_type == 2 || auctionData.product.postage_type == 3 || auctionData.product.postage_type == 4"
          class="flex align-items-c"
          :style="`line-height: ${facade.lineHeight / 75}rem;font-size: ${facade.fontSize / 75}rem`"
        >
          <div
            class="l"
            :style="`color: ${facade.color};font-weight: ${facade.fontWeight};`"
          >运费</div>
          <div
            v-if="auctionData.product.postage_type == 4"
            class="r line1"
            :style="`color: ${facade.otherColor};`"
          >到付</div>
          <div
            v-else-if="auctionData.product.postage_type == 3"
            class="r line1"
            :style="`color: ${facade.otherColor};`"
          >包邮</div>
          <div
            v-else-if="auctionData.product.postage_type == 2"
            class="r line1"
            :style="`color: ${facade.otherColor};`"
          >{{ formatPriceNumber(auctionData.product.postage_price, 2) }}{{ merchantInfo ? merchantInfo.fee_type_text : '' }}</div>
        </div>
        <div
          v-if="auctionData.product.service_fake_indemnity || auctionData.product.service_seven_refund"
          class="flex align-items-c"
          :style="`line-height: ${facade.lineHeight / 75}rem;font-size: ${facade.fontSize / 75}rem`"
        >
          <div
            class="l"
            :style="`color: ${facade.color};font-weight: ${facade.fontWeight};`"
          >星级服务</div>
          <div
            class="r line1"
            :style="`color: ${facade.otherColor};`"
          ><span
              class="mr5"
              v-if="auctionData.product.service_fake_indemnity"
            >保真</span><span
              class="mr5"
              v-if="auctionData.product.service_seven_refund"
            >7天包退</span></div>
        </div>
        <div
          v-if="auctionData.bid_type == 1"
          class="flex align-items-c"
          :style="`line-height: ${facade.lineHeight / 75}rem;font-size: ${facade.fontSize / 75}rem`"
        >
          <div
            class="l"
            :style="`color: ${facade.color};font-weight: ${facade.fontWeight};`"
          >加价幅度</div>
          <div
            class="r line1"
            :style="`color: ${facade.otherColor};`"
          >{{ formatPriceNumber(auctionData.plus_price, 2) }}{{ merchantInfo ? merchantInfo.fee_type_text : '' }}</div>
        </div>
        <div
          v-if="auctionData.venue && auctionData.venue.venue_id"
          class="flex align-items-c"
          :style="`line-height: ${facade.lineHeight / 75}rem;font-size: ${facade.fontSize / 75}rem`"
        >
          <div
            class="l"
            :style="`color: ${facade.color};font-weight: ${facade.fontWeight};`"
          >拍卖会</div>
          <div
            class="r line1"
            :style="`color: ${facade.otherColor};`"
          >{{ auctionData.venue.title }}</div>
        </div>
        <div
          v-if="auctionData.album && auctionData.album.album_id"
          class="flex align-items-c"
          :style="`line-height: ${facade.lineHeight / 75}rem;font-size: ${facade.fontSize / 75}rem`"
          @click="goAlbumAction(auctionData.album.album_id)"
        >
          <div
            class="l"
            :style="`color: ${facade.color};font-weight: ${facade.fontWeight};`"
          >专场</div>
          <div
            class="r line1"
            :style="`color: ${facade.otherColor};`"
          >{{ auctionData.album.album_name }}</div>
          <img
            src="https://artimg2.artart.cn/image/20220105/c4a32792718b472c47d4b5dcab146abe.png"
            alt=""
          >
        </div>
        <div
          v-if="auctionData.album && auctionData.album.album_id"
          class="flex align-items-c"
          :style="`line-height: ${facade.lineHeight / 75}rem;font-size: ${facade.fontSize / 75}rem`"
        >
          <div
            class="l"
            :style="`color: ${facade.color};font-weight: ${facade.fontWeight};`"
          >拍品数</div>
          <div
            class="r line1"
            :style="`color: ${facade.otherColor};`"
          >{{ auctionData.album.auction_num }}件</div>
        </div>
        <div
          v-if="auctionData.album && auctionData.album.album_id && auctionData.auction_type != 4"
          class="flex align-items-c"
          :style="`line-height: ${facade.lineHeight / 75}rem;font-size: ${facade.fontSize / 75}rem`"
        >
          <div
            class="l"
            :style="`color: ${facade.color};font-weight: ${facade.fontWeight};`"
          >预展时间</div>
          <div
            class="r line1"
            :style="`color: ${facade.otherColor};`"
          >{{ parseTime(auctionData.album.scheduled_show_time, '{y}年{m}月{d}日') }} 至 {{ parseTime(auctionData.album.album_start_time, '{m}月{d}日') }}</div>
        </div>
        <div
          v-if="auctionData.album && auctionData.album.album_id && auctionData.start_time"
          class="flex align-items-c"
          :style="`line-height: ${facade.lineHeight / 75}rem;font-size: ${facade.fontSize / 75}rem`"
        >
          <div
            class="l"
            :style="`color: ${facade.color};font-weight: ${facade.fontWeight};`"
          >开拍时间</div>
          <div
            class="r line1"
            :style="`color: ${facade.otherColor};`"
          >{{ parseTime(auctionData.start_time, '{y}年{m}月{d}日 {h}:{i}') }}</div>
        </div>
        <div
          v-if="auctionData.venue && auctionData.venue.venue_id"
          class="flex align-items-c"
          :style="`line-height: ${facade.lineHeight / 75}rem;font-size: ${facade.fontSize / 75}rem`"
        >
          <div
            class="l"
            :style="`color: ${facade.color};font-weight: ${facade.fontWeight};`"
          >预展地点</div>
          <div
            class="r line1"
            :style="`color: ${facade.otherColor};`"
          >{{auctionData.venue.address_name}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { parseTime, formatPriceNumber } from "@/utils/util";
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  props: {
    auctionData: [String, Object],
    facade: Object,
    pageInfo: Object,
    merchant: Object,
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const showBidRangeAction = () => {
      emit("showBidRangeAction");
    };

    const merchantInfo = computed(() => {
      if (props.merchant) {
        return props.merchant;
      }
      return store.getters.merchantInfo ? store.getters.merchantInfo : {};
    });

    const goAlbumAction = (id) => {
      if (
        window.history &&
        window.history.state &&
        window.history.state.back &&
        window.history.state.back.indexOf(
          "/pages/albumDetail/index?id=" + id
        ) == 0
      ) {
        router.back();
      } else {
        router.push("/pages/albumDetail/index?id=" + id);
      }
    };

    const goRuleShow = () => {
      router.push("/users/agreement?type=1&merchantid=" + props.auctionData.mer_id);
    };

    return {
      showBidRangeAction,
      merchantInfo,
      parseTime,
      formatPriceNumber,
      goAlbumAction,
      goRuleShow
    };
  },
};
</script>

<style lang="scss" scoped>
.desc {
  &:first-child {
    border-top: none;
  }

  .params {
    width: 100%;
    font-size: 24px;

    .flex {
      border-top: 2px solid #eaeced;
    }

    &:first-child {
      border-top: none;
    }

    .item {
      margin-right: 40px;

      span {
        margin-left: 10px;
        font-weight: 500;
        font-family: NUM;
      }
    }

    .link {
      color: #347ff4;
    }
  }
  img {
    width: 32px;
    height: 32px;
  }
}

.rule {
  position: absolute;
  right: 0;
  top: 0;
}

.params .l {
  width: 140px;
  margin-right: 14px;
  color: #000;
}
.params .r {
  color: #999;
  flex: 1;
  max-height: 75000px;
}
.params img {
  width: 32px;
  height: 32px;
  display: block;
}
.params .link {
  color: #347ff4;
}
</style>