<template>
  <div
    :style="{ padding: `${(facade.marginTop / 75)}rem ${(pageStyleData.page_margin / 75)}rem 0.53333rem ${(pageStyleData.page_margin / 75)}rem`, background: facade.background }"
    v-if="auctionData"
  >
    <div class="flex align-items-c">
      <div
        class="flex-one"
        :style="{ fontSize: (facade.fontSize / 75) + 'rem', color: facade.color, fontWeight: facade.fontWeight }"
      >
        <template v-if="auctionData.auction_status !== 4 && auctionData.auction_status">
          <template v-if="!auctionData.now_price && !auctionData.start_price">
            <span
              class="price-family"
              :style="{ fontSize: (facade.priceFontSize / 75) + 'rem', color: facade.priceColor, fontWeight: 500 }"
            >无底价</span>
          </template>
          <template v-else-if="merchantInfo && merchantInfo.fee_type == 'CNY'"><span>
              <template v-if="auctionData.auction_status === 1 && auctionData.now_price">当前价</template>
              <template v-else-if="auctionData.auction_status === 1 || auctionData.auction_status === 2">起拍价</template>
              <template v-else-if="auctionData.auction_status === 3">当前价</template>
              <template v-else>落槌价</template>
            </span><span
              class="price-family ml5"
              :style="{ fontSize: (facade.priceFontSize / 75) + 'rem', color: facade.priceColor, fontWeight: 500 }"
            >{{ !auctionData.now_price ? formatPriceNumber(auctionData.start_price, 2) : formatPriceNumber(auctionData.now_price, 2) }}</span><span :style="{color: facade.priceColor}">元</span></template>
          <AmountSymbol
            :textStyle="`font-size: ${facade.fontSize / 75}rem;color: ${facade.color};font-weight: ${facade.fontWeight};margin-right: ${10 / 75}rem;`"
            :text="!auctionData.now_price ? '起拍价' : ''"
            class="price-family"
            :style="`font-size: ${facade.priceFontSize / 75}rem;color: ${facade.priceColor};font-weight: 500;`"
            v-else-if="merchantInfo && merchantInfo.fee_type != 'CNY'"
            :price="!auctionData.now_price ? auctionData.start_price : auctionData.now_price"
            :merchant="merchantInfo"
          ></AmountSymbol>
        </template>
      </div>
      <div
        v-if="auctionData.album"
        class="around flex align-items-c"
        :style="`font-size: ${facade.fontSize / 75}rem;color: ${facade.color};`"
      >
        <div
          class="l item-flex-center"
          @click="goAtherPage(auctionData.adjacent_id ? auctionData.adjacent_id.previous_id : 0)"
        >
          <img
            v-if="!auctionData.adjacent_id || !auctionData.adjacent_id.previous_id"
            src="https://artimg2.artart.cn/image/20220319/ab61bd3a1dfa5931e107de149d2c81b9.png"
            alt=""
          >
          <img
            v-else
            src="https://artimg2.artart.cn/image/20220319/96202d9407dfc6aaf21bb11b23234aca.png"
            alt=""
          >
        </div>
        <div class="c item-flex-center">
          <div style="color: #000;font-weight: 500;">{{ auctionData.order_num }}</div>
          <div>/{{ auctionData.album.auction_num }}件</div>
        </div>
        <div
          class="r item-flex-center"
          @click="goAtherPage(auctionData.adjacent_id ? auctionData.adjacent_id.next_id : 0)"
        >
          <img
            v-if="!auctionData.adjacent_id || !auctionData.adjacent_id.next_id"
            src="https://artimg2.artart.cn/image/20220319/880bcf0790c25d6901889a9aab9185f9.png"
            alt=""
          >
          <img
            v-else
            src="https://artimg2.artart.cn/image/20220319/23fa55d9e434cd0d1b029e6c90d0fc1c.png"
            alt=""
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatPriceNumber } from "@/utils/util";
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  props: {
    auctionData: [String, Object],
    facade: Object,
    pageStyleData: Object,
    merchant: Object,
  },
  setup(props) {
    const store = useStore();
    const merchantInfo = computed(() => {
      if (props.merchant) {
        return props.merchant;
      }
      return store.getters.merchantInfo ? store.getters.merchantInfo : {};
    });
    const router = useRouter();

    const goAtherPage = (id) => {
      // 跳转
      if (id) {
        router.replace(`/pages/auctionDetail/index?id=${id}`);
      }
    };
    return {
      merchantInfo,
      formatPriceNumber,
      goAtherPage
    };
  },
};
</script>

<style lang="scss" scoped>

.around{
  height: 50px;
}

.around .l, .around .r{
  width: 80px;
  height: 50px;
  background: #F5F5F5;
  border-radius: 8px;
}

.around .l img, .around .r img{
  width: 32px;
  height: 32px;
  display: block;
}

.around .c{
  padding: 0 20px;
  font-size: 24px;
  color: #999;
}

</style>