<template>
  <div
    :style="{ padding: `${(facade.marginTop / 75)}rem ${(pageInfo.page_margin / 75)}rem 0 ${(pageInfo.page_margin / 75)}rem`, background: facade.background }"
    v-if="auctionData"
  >
    <span
      class="ml5"
      :style="{
        color: facade.color,
        fontWeight: facade.fontWeight,
        fontSize: (facade.fontSize / 75) + 'rem',
      }"
    >
      {{ auctionData.lot ? 'LOT' + auctionData.lot + ' ' : '' }}{{ auctionData.product.store_name }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    auctionData: [String, Object],
    facade: Object,
    pageInfo: Object,
  },
  setup() {},
};
</script>

<style lang="scss" scoped>
.ml5 {
  margin-left: 10px;
}
</style>