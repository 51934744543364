<template>
  <div
    v-if="auctionData"
    :style="{ padding: `0 ${(pageInfo.page_margin / 75)}rem`, background: facade.background, marginTop: (facade.marginTop / 75) + 'rem' }"
  >
    <div
      class="bor"
      :style="{ lineHeight: (pageInfo.tLineHeight / 75) + 'rem', fontSize: (pageInfo.tFontSize / 75) + 'rem', color: pageInfo.tColor, fontWeight: pageInfo.tFontWeight }"
    >拍品详情</div>
    <div class="params">
      <div
        v-if="auctionData.reference_price"
        class="flex align-items-c"
        :style="`line-height: ${(facade.attrFontSize + 10) / 75}rem;padding: ${((facade.attrLineHeight - (facade.attrFontSize + 10)) / 2) / 75}rem 0;font-size: ${facade.attrFontSize / 75}rem`"
      >
        <div
          class="l"
          :style="`color: ${facade.attrColor};font-weight: ${facade.attrFontWeight};`"
        >估价</div>
        <div
          class="r"
          :style="`color: ${facade.attrOtherColor};`"
        >{{ auctionData.reference_price }}</div>
      </div>
      <template v-if="auctionData.attr_info && auctionData.attr_info.length > 0">
        <div
          v-for="(item, index) in auctionData.attr_info"
          :key="index"
          class="flex align-items-c"
          :style="`line-height: ${(facade.attrFontSize + 10) / 75}rem;padding: ${((facade.attrLineHeight - (facade.attrFontSize + 10)) / 2) / 75}rem 0;font-size: ${facade.attrFontSize / 75}rem`"
        >
          <div
            class="l"
            :style="`color: ${facade.attrColor};font-weight: ${facade.attrFontWeight};`"
          >{{item.attr_name}}</div>
          <div
            class="r"
            :style="`color: ${facade.attrOtherColor};`"
          >{{item.attr_value}}</div>
        </div>
      </template>
    </div>
    <div
      class="content message-view"
      :style="{ color: facade.color, fontWeight: facade.fontWeight, lineHeight: (facade.lineHeight / 75) + 'rem', fontSize: (facade.fontSize / 75) + 'rem' }"
    >
      <div v-html="auctionData.content"></div>
    </div>
    <div
      class="img-list"
      v-if="auctionData.product.slider_image && auctionData.product.slider_image.length > 0"
      :style="`padding-bottom: ${32 / 75}rem;`"
    >
      <div
        class="imgs"
        v-for="(item, index) in auctionData.product.slider_image"
        :key="`goodsImg${index}`"
        @click="showBigImgAction(index)"
      >
        <img
          :src="item + '!m640'"
          v-if="item"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { parseTime, formatPriceNumber } from "@/utils/util";
import { computed, toRefs } from "vue";
import { useStore } from "vuex";
import { ImagePreview } from "vant";

export default {
  props: {
    auctionData: [String, Object],
    facade: Object,
    pageInfo: Object,
    merchant: Object,
  },
  setup(props, { emit }) {
    const { auctionData } = toRefs(props);
    const store = useStore();
    const pTime = (time, mat) => {
      return parseTime(time * 1000, mat);
    };

    const showBidRangeAction = () => {
      emit("showBidRangeAction");
    };

    const merchantInfo = computed(() => {
      if (props.merchant) {
        return props.merchant
      }
      return store.getters.merchantInfo ? store.getters.merchantInfo : {};
    });

    const showBigImgAction = (index) => {
      ImagePreview({
        images: auctionData.value.product.slider_image,
        startPosition: index,
      });
    }

    return {
      pTime,
      showBidRangeAction,
      merchantInfo,
      formatPriceNumber,
      showBigImgAction
    };
  },
};
</script>

<style lang="scss" scoped>
.message-view {
  padding: 20px 0;
  max-height: 999999px;
}

.bor {
  border-bottom: 2px solid #eaeced;
}

.params {
  font-size: 24px;
  .flex {
    padding: 10px 0;
    line-height: 40px;
    border-bottom: 2px solid #eaeced;
  }
  .l {
    width: 140px;
    margin-right: 14px;
    color: #000;
  }

  .r {
    color: #999;
    flex: 1;
    max-height: 75000px;
  }

  .link {
    color: #347ff4;
  }
}

.xt {
  margin-top: 32px;
  font-weight: 500;
  color: #000;
}

.img-list .imgs{
  margin-top: 20px;
  width: 100%;
}
.img-list .imgs img{
  width: 100%;
  display: block;
}
</style>