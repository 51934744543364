<template>
  <div
    v-if="total"
    :style="{ padding: `0 ${(pageInfo.page_margin / 75)}rem`, background: facade.background, marginTop: (facade.marginTop / 75) + 'rem' }"
  >
    <div :style="{ lineHeight: (pageInfo.tLineHeight / 75) + 'rem', fontSize: (32 / 75) + 'rem', color: pageInfo.tColor, fontWeight: pageInfo.tFontWeight, fontSize: '0.42666rem' }">竞拍记录（{{ total }}）</div>
    <div class="bid-record">
      <div class="item flex align-items-c" v-for="(item, index) in list" :key="index">
        <div class="l">
          <img
            v-if="item.user"
            class="avatar"
            :src="item.user.avatar"
          >
        </div>
        <div class="c flex-one">
          <div
            class="name line1"
            :style="{ fontSize: (facade.fontSize / 75) + 'rem', color: facade.color, fontWeight: facade.fontWeight }"
          >{{ item.uid == uid ? '我' : item.user.nickname }}<span class="ft20 colorGray" style="font-weight: 400;" v-if="item.is_proxy_bid == 1">(委托)</span></div>
          <div class="time color-gray ft12">{{ item.create_time }}</div>
        </div>
        <div
          class="price ft12 ml10"
          :style="{ color: facade.priceColor }"
        ><div :style="{ fontSize: (facade.priceFontSize / 75) + 'rem', fontWeight: facade.priceFontWeight }"><AmountSymbol :price="item.bid_price" type="3" /></div></div>
        <div class="r">
          <img
            v-if="index === 0"
            :src="tag_style.firstImg"
            alt=""
          >
          <img
            v-else
            :src="tag_style.endImg"
            alt=""
          >
        </div>
      </div>
      <div class="show flex align-items-c justify-c ft12" v-if="total > 5 && total > list.length" @click="openListAction">
        <div>查看更多出价</div>
        <img
          src="https://artimg2.artart.cn/image/20220109/997f63f87c898a4bdd3ab75043ab3d2d.png"
          alt=""
        >
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { formatPriceNumber } from "@/utils/util";
import { useStore } from "vuex";

export default {
  props: {
    auctionData: [String, Object],
    facade: Object,
    pageInfo: Object,
    list: Array,
    total: Number
  },
  setup(props, content) {
    const store = useStore();
    
    const tag_style = computed(() => {
      return store.getters.tag_style
        ? store.getters.tag_style
        : {};
    });

    const openListAction = () => {
      content.emit('loadMore');
    }

    // 右按钮样式
    const uid = computed(() => {
      return store.getters.uid
        ? store.getters.uid
        : '';
    });
    
    return {
      tag_style,
      formatPriceNumber,
      uid,

      openListAction
    }
  },
};
</script>

<style lang="scss" scoped>

.bid-record{
  .item{
    height: 160px;
    border-top: 2px solid #EAECED;
  }
  .l{
    .avatar{
      width: 80px;
      height: 80px;
      border-radius: 50%;
      display: block;
    }
  }
  .c{
    overflow: hidden;
    margin-left: 16px;

    .time{
      margin-top: 2px;
    }
  }
  .r{
    margin-left: 10px;
    img{
      width: 68px;
      height: 64px;
      display: block;
    }
  }

  .price{
    width: 232px;
  }

  .show{
    border-top: 2px solid #EAECED;
    height: 80px;
    color: #A1A4BA;
    img{
      width: 32px;
      height: 32px;
      display: block;
    }
  }
}
</style>